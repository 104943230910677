import React, { useState, useEffect, useCallback } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import MobileMenu_New from "./MobileMenu_New"
import { useBoolean } from "react-hookedup"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const EBgFiller = styled.div`
  height: 4em;
  transition: all 0;
  @media (max-width: 700px) {
    height: 52px;
  }

  &[data-headerisfullsize="true"] {
    height: 6em;

    @media (max-width: 700px) {
      height: 70px;
    }
  }
`

const EHeader = styled.header`
  align-items: center;
  background: #fff;
  display: flex;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  justify-content: center;
  position: fixed;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 777;
  box-shadow: 0 0.36em 1.18em 0 rgba(0, 0, 0, 0.08);
  height: 4em;
  padding: 0 2em;
  background: 0px -50px 8px 0px #fff;

  @media (max-width: 700px) {
    height: 52px;
    padding-bottom: 0;
    align-items: center;
    justify-content: center;
    display: flex;

    > * {
      align-self: unset !important;
    }
  }

  &[data-headerisfullsizeornobottom="true"] {
    box-shadow: unset;
  }

  &[data-headerisfullsize="true"] {
    height: 6em;

    @media (max-width: 700px) {
      height: 70px;
    }
  }
`

const EHeaderContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 103em;
  width: 100%;
  @media (max-width: 43.75em) {
    align-self: flex-end;
  }
`

const ENav = styled.nav`
  align-self: center;
  justify-self: end;
  @media (max-width: 43.75em) {
    display: none;
  }
`

const Eul = styled.ul`
  grid-column-gap: 1.5em;
  display: grid;
  grid-template-columns: repeat(3, min-content);
  margin: 0px;
  padding: 0px;
`

const Eli = styled.li`
  align-items: center;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin: 0px;
  padding: 0px;
`

const ButtoWithUnderlineHoistingStr = css`
  cursor: pointer;
  display: block;
  position: relative;
  text-decoration: none;
  transition: all 0.3s;
  letter-spacing: 0.07em;
  font-size: 1.3em;
  font-weight: 500;
  color: #525151;
`

const ButtoWithUnderlineHoistingActive = css`
  color: #fd7114;
  text-decoration: none;
`

const EButtoWithUnderlineHoistingLink = styled(Link)`
  ${ButtoWithUnderlineHoistingStr};
  color: #333;
  &:after {
    background-color: #333;
  }
`

const EBurgerButton = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  margin-right: 20px;
  grid-row-gap: 12px;
`

const EBurgerLine = styled.div`
  background-color: #5a5d5e;
  height: 3px;
  width: 38px;
`

const EApikoLogoLink = styled(Link)`
  transition: width 0.2s;
  align-items: center;
  display: flex;
`

const BurgerButton = styled.button`
  background-color: transparent;
  border: unset;
  cursor: pointer;

  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 42px;
  align-self: flex-end;
  padding: 0px;
  @media (min-width: 701px) {
    display: none;
  }
`

const EApikoLogoImg = styled.div`
  img {
    transition: all 0.1s !important ;
  }

  @media (min-width: 701px) {
    img {
      width: 19em !important ;
    }
  }

  @media (max-width: 700px) {
    * div {
      max-width: unset !important;
      width: 200px !important ;
    }
    img {
      width: 200px !important ;
    }
  }

  &[data-headerisfullsize="true"] {
    @media (min-width: 701px) {
      img {
        width: 22em !important ;
      }
    }

    @media (max-width: 700px) {
      * div {
        width: 220px !important ;
      }
      img {
        width: 220px !important ;
      }
    }
  }
`

const NavLink = (props) => {
  return (
    <div
      css={{
        "& .ButtoWithUnderlineHoistingStr": css(ButtoWithUnderlineHoistingStr),

        "& .ButtoWithUnderlineHoistingStrActive": css(
          ButtoWithUnderlineHoistingStr,
          ButtoWithUnderlineHoistingActive
        ),
      }}
    >
      <EButtoWithUnderlineHoistingLink
        {...props}
        getProps={(props) => {
          return {
            className: props.isPartiallyCurrent
              ? "ButtoWithUnderlineHoistingStrActive"
              : "ButtoWithUnderlineHoistingStr",
          }
        }}
      />
    </div>
  )
}

const NavLinkMemo = React.memo((props) => {
  return <NavLink {...props} />
})

const MobileMenuMemo = React.memo(
  ({ open, toggle, close }) => {
    return (
      <>
        <MobileMenu_New open={open} toggle={toggle} close={close} />
      </>
    )
  },
  (prev, next) => prev.open === next.open
)

const HomeLink = React.memo(
  ({ headerIsFullSize }) => {
    return (
      <EApikoLogoLink to="/">
        <EApikoLogoImg data-headerisfullsize={headerIsFullSize}>
          <StaticImage
            src="../images/logo.png"
            placeholder="none"
            layout="constrained"
            objectFit="contain"
            formats={["png"]}
            width={300}
            quality={60}
          />
        </EApikoLogoImg>
      </EApikoLogoLink>
    )
  },
  (prev, next) => prev.headerIsFullSize === next.headerIsFullSize
)

const Header = React.memo(
  ({ noBottomShadow, headerIsFullSize, modalIsOpen, onToggleModal }) => {
    return (
      <>
        <EBgFiller data-headerisfullsize={true} />

        <EHeader
          data-headerisfullsize={headerIsFullSize}
          data-headerisfullsizeornobottom={headerIsFullSize || noBottomShadow}
        >
          <EHeaderContent>
            <HomeLink headerIsFullSize={headerIsFullSize} />

            <ENav>
              <Eul>
                <Eli>
                  <NavLinkMemo to={"/about-us"}>Про нас</NavLinkMemo>
                </Eli>
                <Eli>
                  <NavLinkMemo to={"/courses/"}>Курси</NavLinkMemo>
                </Eli>
                <Eli>
                  <NavLinkMemo to={"/faq/"}>FAQs</NavLinkMemo>
                </Eli>
              </Eul>
            </ENav>
          </EHeaderContent>

          <BurgerButton onClick={onToggleModal}>
            <EBurgerButton>
              <EBurgerLine />
              <EBurgerLine />
              <EBurgerLine />
            </EBurgerButton>
          </BurgerButton>
        </EHeader>
      </>
    )
  },
  (prev, next) =>
    prev.noBottomShadow === next.noBottomShadow &&
    prev.headerIsFullSize === next.headerIsFullSize &&
    prev.modalIsOpen === next.modalIsOpen
)

const Header1 = (props) => {
  const [headerIsFullSize, setHeaderIsFullSize] = useState(true)
  const modalOpen = useBoolean(false)

  const onScrollCalback = useCallback(
    (scrollPosition) => setHeaderIsFullSize(scrollPosition < 1),
    []
  )

  useEffect(() => {
    updateScrollPosition()

    window.addEventListener("scroll", updateScrollPosition)

    return () => {
      window.removeEventListener("scroll", updateScrollPosition)
    }
  }, [])

  const updateScrollPosition = () => {
    onScrollCalback(window.pageYOffset)
  }

  return (
    <>
      <Header
        headerIsFullSize={headerIsFullSize}
        modalIsOpen={modalOpen.value}
        onToggleModal={modalOpen.toggle}
        closeModal={modalOpen.setFalse}
      />
      <div
        css={css`
          @media (min-width: 701px) {
            display: none;
          }
        `}
      >
        <MobileMenuMemo
          open={modalOpen.value}
          toggle={modalOpen.toggle}
          close={modalOpen.setFalse}
        />
      </div>
    </>
  )
}

export default Header1
