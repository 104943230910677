import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
import x2 from "../images/icons/x2.svg"
import styled from "@emotion/styled"

const EMenuItem = styled(Link)`
  display: block;
  text-align: center;
  padding: 18px 15px;
  font-size: 20px;
  border: 0;
  font-weight: 400;
  width: fit-content;
  margin-left: 20px;
  transition: all 1s ease;
  color: #333;
  text-decoration: none;
  cursor: pointer;

  &[data-highlight="true"] {
    color: #fd7114;
    font-weight: 600;
  }
`

const EDrawerItself = styled.div`
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  background-color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const EClose = styled.button`
  overflow: 0;
  padding: 20px;
  width: 73px;
  height: 80px;
  position: absolute;
  background: transparent;
  border: 0;
  right: 30px;
  top: 0px;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
`

const MobileMenuContentNew = ({ handleClose }) => {
  return (
    <EDrawerItself>
      <EClose
        onClick={() => {
          handleClose()
        }}
      >
        <img src={x2} alt="close btn" />
      </EClose>

      <EMenuItem
        css={css`
          margin-top: 76px;
        `}
        to={"/about-us"}
      >
        Про нас
      </EMenuItem>

      <EMenuItem to={"/courses"}>Курси</EMenuItem>

      <EMenuItem to={"/faq"}>FAQs</EMenuItem>
    </EDrawerItself>
  )
}

export default MobileMenuContentNew
