import React from "react"
import { Helmet } from "react-helmet"
import { Global, css } from "@emotion/react"
import { isIOS } from "react-device-detect"
import { graphql, StaticQuery } from "gatsby"

const Seo = ({ data }) => {
  return (
    <>
      <Helmet>
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '561481435188040');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            style="display:none"
            src="https://www.facebook.com/tr?id=561481435188040&ev=PageView&noscript=1"
          />`}
        </noscript>
      </Helmet>
      {isIOS && (
        <Global
          styles={css`
            @media screen and (max-width: 1500px) {
              input,
              select,
              textarea {
                font-size: 16px !important;
              }
            }
          `}
        />
      )}

      <Global
        styles={css`
          * {
            -webkit-tap-highlight-color: rgba(253, 115, 23, 0.11) !important;
            outline: none;
          }

          html {
            padding: unset;
            margin: unset;
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
          }

          body {
            margin: 0;
          }

          #___gatsby {
            font-size: 0.85em;
          }

          @media (max-width: 1850px) {
            #___gatsby {
              font-size: 0.8em;
            }
          }

          @media (max-width: 1800px) {
            #___gatsby {
              font-size: 0.775em;
            }
          }

          @media (max-width: 1750px) {
            #___gatsby {
              font-size: 0.75em;
            }
          }

          @media (max-width: 1650px) {
            #___gatsby {
              font-size: 0.7em;
            }
          }

          @media (max-width: 850px) {
            #___gatsby {
              font-size: 0.65em;
            }
          }

          @media (max-width: 800px) {
            #___gatsby {
              font-size: 0.6em;
            }
          }

          *,
          *:before,
          *:after {
            box-sizing: border-box;
          }

          button,
          a,
          textarea,
          input {
            font: inherit;
          }

          img {
            color: #fff0;
          }
        `}
      />

      <Global
        styles={css`
          .slick-slider {
            position: relative;
            display: block;
            box-sizing: border-box;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            -webkit-touch-callout: none;
            -khtml-user-select: none;
            -ms-touch-action: pan-y;
            touch-action: pan-y;
            -webkit-tap-highlight-color: transparent;
          }

          .slick-list {
            position: relative;
            display: block;
            overflow: hidden;
            margin: 0;
            padding: 0;
          }
          .slick-list:focus {
          }
          .slick-list.dragging {
            cursor: pointer;
            cursor: hand;
          }

          .slick-slider .slick-track,
          .slick-slider .slick-list {
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
          }

          .slick-track {
            position: relative;
            top: 0;
            left: 0;

            display: block;
            margin-left: auto;
            margin-right: auto;
          }
          .slick-track:before,
          .slick-track:after {
            display: table;

            content: "";
          }
          .slick-track:after {
            clear: both;
          }
          .slick-loading .slick-track {
            visibility: hidden;
          }

          .slick-slide {
            display: none;
            float: left;

            height: 100%;
            min-height: 1px;
          }
          [dir="rtl"] .slick-slide {
            float: right;
          }
          .slick-slide img {
            display: block;
          }
          .slick-slide.slick-loading img {
            display: none;
          }
          .slick-slide.dragging img {
            pointer-events: none;
          }
          .slick-initialized .slick-slide {
            display: block;
          }
          .slick-loading .slick-slide {
            visibility: hidden;
          }
          .slick-vertical .slick-slide {
            display: block;
            height: auto;
            border: 1px solid transparent;
          }
          .slick-arrow.slick-hidden {
            display: none;
          }
        `}
      />
    </>
  )
}

const Seo1 = (props) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "ApikoLogoFull1000.png" }) {
          id
          name
          relativePath
          publicURL
        }
      }
    `}
    render={(data) => <Seo data={data} {...props} />}
  />
)

export default Seo1
