import React, { useEffect, useState } from "react"
import MobileMenuContent_New from "./MobileMenuContent_New"
import { css, keyframes } from "@emotion/react"
import styled from "@emotion/styled"

const a1 = keyframes`
    0% {
      filter: opacity(0);
    }
    
   40% {
      filter: opacity(0);
    }

    100% {
      filter: opacity(1);
    }
`

const EWrapper = styled.section`
  left: calc(100vw + 30px);
  transition: left 0.2s;
  width: 100vw;
  height: 100vh;
  top: 0;
  position: fixed;
  z-index: 99999999;

  &[data-open="true"] {
    left: 0px;
  }
`

const EBg = styled.div`
  transition: background 0.5s, box-shadow 0.5s;
  background: #00000066;
  box-shadow: inset 0 0 10vw #ffffff00;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  z-index: -1;
  animation: ${a1} 400ms ease-in-out forwards;

  &[data-open="true"] {
    box-shadow: inset 0 0 10vw #ffffff00;
  }
`

const MobileMenuNew = React.memo(
  ({ close, open, toggle }) => {
    const [rendered, setRendered] = useState(false)

    useEffect(() => {
      setRendered(true)
    }, [])

    const handleClose = () => {
      toggle()
    }

    if (rendered) {
      return (
        <EWrapper data-open={open}>
          <>
            {open && (
              <EBg
                data-open={open}
                onClick={() => {
                  close()
                }}
              />
            )}
            <MobileMenuContent_New
              open={open}
              handleClose={close}
            />
          </>
        </EWrapper>
      )
    }

    return null
  },
  (prev, next) => prev.open === next.open
)

export default MobileMenuNew
