import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Link } from "gatsby"
import AwhiteSVG from "../images/icons/a-white.svg"
import InstagramSVG from "../images/icons/bxl-instagram-alt.svg"
import TelegramSVG from "../images/icons/bxl-telegram.svg"
import YoutubeSVG from "../images/icons/bxl-youtube.svg"
import academySVG from "../images/logo-white.svg"
import FacebookSVG from "../images/icons/fbk.svg"

const EFooter = styled.footer`
  background-color: #333a4a;
`

const EFooterContent = styled.div`
  padding: 2em;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  font-family: "Rubik", Arial, Helvetica, sans-serif;
  letter-spacing: 0.06em;
  grid-column-gap: 4em;
  grid-row-gap: 4em;
  align-items: center;
  max-width: 106em;
  margin: auto;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    max-width: 300px;
  }

  @media (max-width: 640px) {
    padding: 5em 0;
  }
`

const ELinks = styled.a`
  text-decoration: none;
  img {
    height: 18px;
  }
`

const LinksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 4em);
  align-items: center;
  justify-items: center;
  justify-content: center;
`

const Component = () => {
  return (
    <EFooter>
      <EFooterContent>
        <img
          src={academySVG}
          css={css`
            height: 36px;

            @media (max-width: 640px) {
              height: 26px;
              margin: auto;
            }
          `}
        />

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 640px) {
              justify-content: space-evenly;
            }
          `}
        >
          <Link
            to="/faq"
            css={css`
              color: #fff;
              text-decoration: none;
              font-size: 15px;
            `}
          >
            FAQ
          </Link>
          <a
            href="mailto:academy@apiko.com"
            css={css`
              color: #fff;
              text-decoration: none;
              font-size: 15px;
            `}
          >
            academy@apiko.com
          </a>
        </div>

        <LinksGrid>
          <ELinks href="https://apiko.com/" target="_blank" rel="noreferrer">
            <img src={AwhiteSVG} />
          </ELinks>

          <ELinks
            href="https://t.me/TernopilJS"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TelegramSVG} />
          </ELinks>

          <ELinks
            href="https://t.me/joinchat/QrkHgZvV0zljU3Bh"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TelegramSVG} />
          </ELinks>

          <ELinks
            href="https://www.youtube.com/c/TernopilJS/featured"
            target="_blank"
            rel="noreferrer"
          >
            <img src={YoutubeSVG} />
          </ELinks>

          <ELinks
            href="https://www.facebook.com/apikocareer/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={FacebookSVG} />
          </ELinks>

          <ELinks
            href="https://www.instagram.com/apiko_com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={InstagramSVG} />
          </ELinks>
        </LinksGrid>
      </EFooterContent>
    </EFooter>
  )
}

export default (props) => <Component {...props} />
